import * as React from "react"
import { Script } from "gatsby"
import * as styles from './InstaRealisations.module.scss';

export const InstaRealisations = () => {
  return (
    <section className={styles.realisations}>
        <div className="embedsocial-hashtag" data-ref="c6e22c05aec6b214588997ecfba7c94e838f5661" />
        <Script src="https://embedsocial.com/cdn/ht.js" />
    </section>
  )
}
