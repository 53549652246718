import * as React from "react"
import * as styles from './Footer.module.scss';

export const Footer = () => {

  return (
    <>
      <svg className={styles.svg} aria-hidden="true" viewBox="0 0 1280 90" preserveAspectRatio="none">
        <path
          d="M 0,90 V 23.75 l 23.4319,5.3125 c 23.4328,5.3125 70.2978,15.9376 117.1631,12.3126 46.866,-3.6875 93.73,-21.5627 140.594,-13.3752 46.866,8.25 93.731,42.6251 140.596,50.7501 46.865,8.125 93.73,-10 140.594,-10.3125 46.864,-0.3125 93.73,17.1875 140.596,20 24.917,1.4956 49.833,-1.1612 74.749,-3.8174 21.949,-2.34 43.897,-4.68 65.847,-4.1819 46.864,1 93.729,14.9999 140.594,3.6249 46.865,-11.375 93.725,-48.0001 140.595,-59.5001 46.87,-11.4375 93.73,2.3126 140.59,-17.37489 C 1270.46,5.02124 1275.35,2.62 1280,0 v 90 z"
          fill="var(--lmdj-background-base)"
        />
      </svg>
      <footer className={styles.footer}>
        <div className={styles.sections}>
          <section className={styles.sectionDevis}>
            <h3 className={styles.title}>Devis et tarifs sur demande</h3>
            <p className={styles.paragraph}>
              La Maison des Jeux réalise ses prestations sur la Bretagne, le Morbihan, L'Ile et Vilaine, le Finistère, les
              Côtes d'Armor, la Loire Atlantique et la Vendée.
            </p>
          </section>

          <section className={styles.sectionNousContacter}>
            <h3 className={styles.titleNousContacter}>Nous contacter</h3>
            
            <section className={styles.subSection}>
              <header className={styles.subSectionTitle}>Adresse</header>
              <address>
                <a className={styles.link} href="https://www.google.fr/maps/place/9+Rue+Commandant+l'Herminier,+56600+Lanester/@47.7642319,-3.3518985,255m/data=!3m1!1e3!4m5!3m4!1s0x48105ef2a6979131:0xa66049dae4d1a149!8m2!3d47.7640197!4d-3.3517646" target="_blank">
                  La Maison des Jeux<br/>
                  9 Rue Commandant l'Herminier<br/>
                  56600 LANESTER
                </a>
              </address>
            </section>

            <section className={styles.subSection}>
              <header className={styles.subSectionTitle}>Email</header>
              <a className={styles.link} href="mailto:mdjlorient@gmail.com">mdjlorient@gmail.com</a>
            </section>

            <section className={styles.subSection}>
              <header className={styles.subSectionTitle}>Téléphone</header>
              <a className={styles.link} href="tel:0667228793">06 67 22 87 93</a>
            </section>
            
          </section>

        </div>

        <ul className={styles.copyright}>
            <li className={styles.copyrightItem}>© Tous droits réservés</li>
            <li className={styles.copyrightItem}>La Maison des jeux</li>
            <li>Réalisé par <a className={styles.mailto} href="mailto:nicolas.guefveneu@gmail.com">Nicolas GUEFVENEU</a></li>
          </ul>
      </footer>
    </>
  )
}
