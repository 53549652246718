// extracted by mini-css-extract-plugin
export var copyright = "Footer-module--copyright--4b9f0";
export var copyrightItem = "Footer-module--copyrightItem--21c4e";
export var footer = "Footer-module--footer--8932c";
export var link = "Footer-module--link--51a23";
export var mailto = "Footer-module--mailto--f916f";
export var paragraph = "Footer-module--paragraph--3f40c";
export var sectionDevis = "Footer-module--sectionDevis--8648b";
export var sectionNousContacter = "Footer-module--sectionNousContacter--f74ae";
export var sections = "Footer-module--sections--bba5e";
export var subSection = "Footer-module--subSection--861f4";
export var subSectionTitle = "Footer-module--subSectionTitle--fe922";
export var svg = "Footer-module--svg--ae74f";
export var title = "Footer-module--title--acde2";
export var titleNousContacter = "Footer-module--titleNousContacter--6eaaa Footer-module--title--acde2";