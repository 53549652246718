import * as React from "react"
import {HeaderRealisations} from "../components/header/HeaderRealisations";
import {Footer} from "../components/footer/Footer";
import {InstaRealisations} from "../components/instaRealisations/InstaRealisations";
import {SEO} from "../components/seo/SEO";

export default function Component () {
  return (
    <>
      <HeaderRealisations/>
      <InstaRealisations />
      <Footer/>
    </>
  )
}

export const Head = () => (
  <SEO />
)
