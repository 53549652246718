import * as React from "react"

export const SEO = () => {
    return (
        <>
        <title>La Maison des Jeux | Entretien, maintenance et pose de vos aires de jeux</title>
        <link rel="icon" href="favicon.ico" />
        <meta name="description" content="La Maison des Jeux réalise, pour votre sécurité, les contrôles et la maintenance de vos aires de jeux, en Bretagne, Morbihan." />
        <meta name="keywords" content="la Maison des jeux, maison, jeux, pose, poseur, installateur, aires de jeux, maintenance, entretien, Bretagne, Morbihan, contrôle, sol, amortissant, souple, ludique, sportif" />
        <meta property="og:title" content="La Maison des Jeux" />
        <meta property="og:description" content="La Maison des Jeux réalise, pour votre sécurité, les contrôles et la maintenance de vos aires de jeux, en Bretagne, Morbihan." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://www.lamaisondesjeux.fr/" />
        <meta property="og:image" content="http://www.lamaisondesjeux.fr/home-screenshot.png" />
        <meta property="og:locale" content="fr_FR" />
        </>
    )
    }
  
